<template>
  <div class="manage-session themev2" v-if="session">

    <h2>{{session.id}} - {{session.name}} - Prepare session</h2>
    <div style="color:#777;font-size:16px;">{{ownerName}}, {{session.startDate ? new Date(session.startDate).toLocaleString() : 'No date'}}</div>
    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="steps">
      <div class="step" :class="{active: step == 1}" @click="step = 1;">
        <span>1</span> Assign devices
      </div>
      <div class="step" :class="{active: step == 2}"  @click="step = 2;">
        <span>2</span> Transfer content
      </div>
    </div>

    <div class="tabs" :style="`height: calc(100lvh - var(--size-header) - 200px)`">
      <div class="usertabs" v-show="step == 1">
        <div class="usertab">
          <div style="display:flex; margin-bottom:15px;">
            <div style="flex-grow:1;white-space: nowrap;padding-right: 15px; font-size:18px;">
              <b style="font-size:20px;">Devices</b>  Available - {{availableTechnicalUsers.length}}<span style="padding-left:10px;"></span> Requested - {{session.userCount}}
              <img v-if="session.userCount > availableTechnicalUsers.length" src="../assets/alert_orange.svg" style="margin-left:5px;width:20px;vertical-align: text-top;">
            </div>
            <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available devices..." /> 
          </div>
          <VueGoodTable
            ref="usersdatatable"
            :columns="sessionDeviceColumns"
            :rows="availableTechnicalUsers"
            striped
            @on-selected-rows-change="selectionChanged"
            :max-height="`calc(100lvh - var(--size-header) - 240px - ${(removeDisabled && addDisabled) ? 100 : 145}px)`"
            :fixed-header="true"
            styleClass="vgt-table"
            :search-options="{
              enabled: false,
              externalQuery: userSearchTerm
            }"
            :select-options="{ 
              enabled: true,
              selectOnCheckboxOnly: false 
            }">
              <!-- <template v-slot:selected-row-actions>  
                <button style="height:35px;" class="autowidth right" :disabled="loading" @click="addUsers">Add to session</button>                   
              </template> -->
          </VueGoodTable>
        </div>
        <div class="middletab">
          <button class="icon" @click="removeUsers" :disabled="loading || removeDisabled"><img src="../assets/slide_r.svg"/></button>
          <button class="icon" @click="addUsers" :disabled="loading || addDisabled"><img src="../assets/slide_l.svg"/></button>          
        </div>
        <div class="usertab">
          <div style="display:flex; margin-bottom:15px;">
            <b>Assigned - {{sessionUsers.length}}</b>
            <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search devices in session..." /> 
          </div>
          <VueGoodTable
            ref="sessionusersdatatable"
            :columns="sessionDeviceColumns"
            :rows="sessionUsers"            
            @on-selected-rows-change="selectionChanged"
            :max-height="`calc(100lvh - var(--size-header) - 240px - ${(removeDisabled && addDisabled) ? 100 : 145}px)`"
            :fixed-header="false"
            styleClass="vgt-table"
            :search-options="{
              enabled: false,
              externalQuery: sessionUserSearchTerm
            }"
            :select-options="{ 
              enabled: true,
              selectOnCheckboxOnly: false 
            }">
              <!-- <template v-slot:selected-row-actions>  
                <button style="height:35px;" class="autowidth right" :disabled="loading" @click="removeUsers">Remove from session</button>                   
              </template> -->
          </VueGoodTable>
        </div>
      </div>

      <div class="usertabs" v-show="step == 2">
        <div class="usertab deploysteps">
          <h2>Deployment steps</h2>          
          <div style="display: flex; gap: 12px; align-items: center; margin: 27px 0;">
            <img src="../assets/connected.svg"/> <div>Make sure devices are connected to the Internet and to a power source. </div>
          </div>
          <hr>
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;">
              <img src="../assets/delete_device.svg"/> 
              <div>
                Delete device content <span style="color:#777;">(optional)</span>
                <br>
                This will remove all files from the selected devices.
              </div>
            </div>
            <button class="white" style="height:30px;" @click="deleteModal = true;" :disabled="loading || !selectedDevices || selectedDevices.length == 0">Delete</button>
          </div>
          <hr>
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;"><img src="../assets/download.svg"/> <div>Start transferring content.<br><span style="color:#777;">Total size of media is: {{humanFileSize(packageSize)}}</span></div></div>
            <button class="white" style="height:30px;" @click="transferModal = true;" :disabled="manualTransfer || !fileTransferJobId || loading || !selectedDevices || selectedDevices.length == 0">Transfer</button>
          </div>    
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;">
              <img src="../assets/tap.svg"/> 
              <div>Manually transfer
                <div>
                  <input type="checkbox" v-model="manualTransfer" style="color:#777; vertical-align:middle; width:16px; height:16px; margin-left:0;" /> <span style="color:#777; vertical-align:middle;">I'm transferring manually</span>
                </div>
              </div>
            </div>            
          </div>       
        </div>
        <div class="middletab">
          <div style="background-color: #d5d5d6; width:1px;height:100%; flex-basis:"></div>
        </div>        
        <div class="usertab">
          <h2 style="position:relative;">Deployment status 
            <span v-if="loading" class="spinner" style="position:absolute; left: 180px; top: 2px; margin: 0;"></span>
            <span v-if="mdmloading" class="spinner" style="position:absolute; left: 180px; top: 2px; margin: 0; padding-left: 30px; font-size: 12px; color: #bbb;">Refreshing...</span>
          </h2>
          <div style="display:flex; margin-bottom:15px;">
            <div style="flex-grow:1; white-space:nowrap;padding-right:20px; padding-top:5px;">
              <img src="../assets/mask.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 15px 0 5px;"> <b style="font-size:16px; padding:0;">{{sessionUsers.length}}</b> Assigned</span>
              <img src="../assets/approved.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 15px 0 5px;"> <b style="font-size:16px; padding:0;">{{deviceReadyCount}}</b> Ready</span>
              <img src="../assets/faild.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 0 0 5px;"> <b style="font-size:16px; padding:0;">{{sessionUsers.length - deviceReadyCount}}</b> content pending</span>
            </div>
            <input v-model="deviceSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search devices..." /> 
          </div>
          <VueGoodTable
            ref="devicesdatatable"
            class="devicesdatatable"
            :class="{manual: manualTransfer}"
            :columns="deviceColumns"
            :rows="devices"
            :row-style-class="rowStyleClassFn"            
            @on-selected-rows-change="devicesSelectionChanged"
            @on-select-all="onSelectAllDevices"
            :max-height="`calc(100lvh - var(--size-header) - 240px - ${(selectedDevices.length == 0) ? 128 : 178}px - 30px)`"
            :fixed-header="false"
            styleClass="vgt-table"
            :search-options="{
              enabled: false,
              externalQuery: deviceSearchTerm,
              searchFn: deviceSearchFn
            }"
            :sort-options="{
              enabled: false
            }"
            :select-options="{ 
              enabled: true,
              selectOnCheckboxOnly: true 
            }">
              <!-- <template v-slot:selected-row-actions>  
                <button style="height:35px;" class="autowidth right" :disabled="loading" @click="addUsers">Add to session</button>                   
              </template> -->
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'" class="rowActions">
                  ?
                </span>
                <span v-else-if="props.column.field == 'name'" :title="props.row.serial ? `SN: ${props.row.serial}` : ''">
                  {{props.row.userName}}
                </span>
                <template v-else-if="props.column.field == 'mdm'">                  
                  <span v-if="!props.row.mdmId" style="color:red; font-weight:bold;">{{props.row.deviceName ? 'No MDM' : 'Device/User Unlinked'}}</span>                  
                  <span v-else class="connection" :title="`MDM Id: ${props.row.mdmId}`">{{props.row.mdmStatus ? props.row.mdmStatus.connectionStatus : '--'}}</span>
                </template>
                <span v-else-if="props.column.field == 'mdmPower' && props.row.mdmStatus" class="power" :class="getBatteryClass(props.row.mdmStatus)">
                  {{`${props.row.mdmStatus.battery}%`}}
                </span>
                <span v-else-if="props.column.field == 'storage' && props.row.mdmStatus" class="storage" :class="{warning: packageSize > props.row.mdmStatus.freeStorage}">
                  {{props.row.mdmStatus ? `${(props.row.mdmStatus.freeStorage/(1000000000)).toFixed(1)}/${(props.row.mdmStatus.storage/(1000000000)).toFixed(1)} GB` : '--'}}
                </span>
                <span v-else-if="props.column.field == 'mdmJob'">
                  {{props.row.jobStatus ? props.row.jobStatus.status : '--'}}
                </span>
                <span v-else-if="props.column.field == 'fileStatus'" class="files" :class="{ready: session.fileList && session.fileList.length == props.row.correctFiles.length}" :title="props.row.deviceName ? 'Click for file details' : ''" @click="fileDetails(props.row)">
                  {{`${props.row.correctFiles.length}/${session.fileList ? session.fileList.length : 0}`}}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
          </VueGoodTable>

          <div v-if="step == 2" style="padding-top:8px; color: #a2a2a2; font-size:14px;">
            The transfer status refreshes automatically every few minutes.
          </div>
        </div>
      </div>

      

      <div class="buttonsrow" v-if="step == 1">
        <span v-if="loading" class="spinner" style="position: static; display: block; margin: 10px 0 0 0;"></span>
        <button class="white" @click="$emit('close')" :disabled="loading">Close</button>
        <button @click="step = 2" :disabled="loading">
          Next
          <img src="../assets/arrow_right_white.svg" style="width:16px; height:16px; transform: translate(5px, 3px);"/>
        </button>
      </div>
      <div class="buttonsrow" v-else>
        <button class="white" @click="$emit('close')">Close</button>
        <button class="white" @click="step = 1">
          <img src="../assets/arrow_r.svg" style="width:16px; height:16px; transform: translate(-3px, 2px) rotate(180deg);"/>
          Back
        </button>
        <button @click="markReadyModal = true;" v-if="session.workflow != 4" :disabled="loading">Mark session as Ready</button>
      </div>
    </div>

    <Modal2 v-if="transferModal" @close="transferModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/download.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Start file transfer</h3>
      <div slot="body">
        After file transfer started, wait until all devices reach status of either DEPLOYED or ERROR/FAILED.
        <br>
        <br>
        Starting another transfer will not cancel any previous attempts that are still scheduled or in progress, and may make devices unresponsive.        
        <div v-if="session.fileTransferJobApplied">
          <br>
          Last transfer started: <b>{{ new Date(session.fileTransferJobApplied).toLocaleString() }}</b>
        </div>
        <div v-if="devicesAlreadyApplied.length > 0">
          <br>
          Transfer was already started previously for <b>{{devicesAlreadyApplied.length}}</b> of the devices.
        </div>
        <div v-if="devicesPending.length > 0">
          <br>
          Transfer still pending for <b>{{devicesPending.length}}</b> of the selected devices.
        </div>     
        <div v-if="devicesPendingOther.length > 0">
          <br>
          <b>{{devicesPendingOther.length}}</b> of the selected devices have other jobs pending in their queue.
        </div>     
      </div>
      <div slot="footer">       
        <button class="right" @click="applyFileTransferJob(); transferModal = false;" :disabled="loading">Start transfer for selected devices</button>   
        <button class="right white" @click="transferModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Modal2 v-if="deleteModal" @close="deleteModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/delete_device.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Delete content on devices</h3>
      <div slot="body">
        After content deletion will be started on devices, please wait a few minutes before pushing any new content!     
        <div v-if="devicesPending.length > 0">
          <br>
          Transfer still pending for <b>{{devicesPending.length}}</b> of the selected devices.
        </div>     
        <div v-if="devicesPendingOther.length > 0">
          <br>
          <b>{{devicesPendingOther.length}}</b> of the selected devices have other jobs pending in their queue.
        </div>        
      </div>
      <div slot="footer">       
        <button class="right" @click="applyEmptyFolderJob(); deleteModal = false;" :disabled="loading">Delete all content on selected devices</button>   
        <button class="right white" @click="deleteModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Modal2 v-if="deviceErrorsModal" @close="deviceErrorsModal = false;">      
      <h3 slot="header">Content issues for device: {{tempDevice.deviceName}}</h3>
      <div slot="body">
        <table v-if="tempDevice.brokenFiles.length > 0">
            <tr style="text-align:left;">
              <th>File</th>
              <th>Server Date</th>
              <th>Device Date</th>
              <th>Reason</th>
            </tr>                      
            <tr v-for="file in tempDevice.brokenFiles" :key="file.key">                
              <td>{{file.key}}</td>
              <td>{{file.serverLastModified}}</td>
              <td>{{file.lastModified ? file.lastModified : ''}}</td>
              <td>{{file.reason}}</td>
            </tr>         
        </table> 
        <div v-else>
          Today is a good day, all content is fine on this device 
          <br>
          (• ◡•)
        </div>
      </div>
      <div slot="footer">               
        <button class="insead white" @click="deviceErrorsModal = false">Close</button>              
        <!-- <button class="insead" @click="exportDeviceErrors">Download CSV</button> -->
      </div>
    </Modal2>  

    <Modal2 v-if="markReadyModal" @close="markReadyModal = false;" class="center">      
      <h3 slot="header">Mark session as Ready</h3>
      <div slot="body">
        After marking a session as ready, you can still come back to this screen. For Professor it will be shown as a fulfilled request and they can start teaching.          
        <div v-if="this.sessionUsers.length != this.deviceReadyCount">
          <br>
          <img src="../assets/alert_orange.svg" style="vertical-align:middle; margin-right:10px;" /> <span style="vertical-align:middle;"><b>{{this.sessionUsers.length - this.deviceReadyCount}}</b> devices don't have all the required content.</span>
          <br>
          <br>
          You can still mark it ready, but <i>those devices won't be functional</i>. 
          <br>
          <br>
          If this is intentional, consider removing those devices from the session instead.
        </div>        
      </div>
      <div slot="footer">       
        <button class="right" @click="markReady(); markReadyModal = false;" :disabled="loading">Confirm</button>   
        <button class="right white" @click="markReadyModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Snackbar ref="snackbar" /> 

  </div>
</template>

<script>
import axios from 'axios'
import Modal2 from '@/components/Modal2.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
//import _ from 'lodash'
import { humanFileSize } from '@/utils.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ManageSession', 
  props: {
    userList: Array,
    experience: Object,
    sessionId: Number
    // TODO either provide externally, or cache it in vuex or something, so we don't re-fetch every time this component is mounted
  },
  components: {
    Modal2,
    Snackbar,
    VueGoodTable
  },
  data: function(){
    return{
      session: undefined,
      error: undefined,
      loading: false,
      mdmloading: false,
      internalUserList: [],
      userSearchTerm: undefined,
      sessionUserSearchTerm: undefined,
      deviceSearchTerm: undefined,
      manualTransfer: false,
      transferModal: false,
      deleteModal: false,
      deviceErrorsModal: false,
      markReadyModal: false,
      sessionDeviceColumns: [
        {
          label: 'Organization',
          field: 'organization.name',
        },
        {
          label: 'Name',
          field: 'firstName',
        }
      ],
      deviceColumns: [        
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Connection',
          field: 'mdm'
        },
        {
          label: 'Power',
          field: 'mdmPower'
        },
        {
          label: 'Free Space',
          field: 'storage'
        },
        {
          label: 'Transfer',
          field: 'mdmJob'
        },
        {
          label: 'Files',
          field: 'fileStatus'
        },
        // {
        //   label: 'Actions',
        //   field: 'actions'
        // }
      ],
      sessionUsers: [],
      step: 1,
      removeDisabled: true,
      addDisabled: true,      
      mdmjobstatus: [],      
      tickerInterval: null,
      vgtReactiveTrigger: 0,
      tempDevice: undefined
    }
  },
  computed: {
    deviceReadyCount(){
      return this.devices.filter(d => d.brokenFiles.length == 0).length
    },
    selectedDevices(){
      return this.vgtReactiveTrigger ? this.$refs.devicesdatatable?.selectedRows : []
    },
    devicesAlreadyApplied(){
      return this.vgtReactiveTrigger ? this.$refs.devicesdatatable?.selectedRows.filter(d => d.jobStatus) : []
    },
    devicesPending(){
      return this.vgtReactiveTrigger ? this.devicesAlreadyApplied.filter(d => ['SCHEDULED','INPROGRESS','PENDING'].includes(d.jobStatus.status)) : []
    },
    devicesPendingOther(){
      return this.vgtReactiveTrigger ? this.$refs.devicesdatatable?.selectedRows.filter(d => d.deviceJobs && d.deviceJobs.find(j => j.jobID != this.fileTransferJobId && ['SCHEDULED','INPROGRESS','PENDING'].includes(j.status))) : []
    },
    mdmAccount(){
      return this.session.organizationId && this.session.organizationId == process.env.VUE_APP_INSEAD_ORG_ID ? "Insead" : "Avris";
    },
    fileTransferJobId(){
      if(!this.session.fileTransferJobId)
        return null
      return !this.session.fileTransferJobId.includes(',') && !this.session.fileTransferJobId.includes('|')
                ? this.session.fileTransferJobId
                : this.session.fileTransferJobId.split(',').find(t => t.startsWith(this.mdmAccount))?.split('|')[1];
    },
    packageSize(){
      return this.session.fileList?.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0) ?? 0      
    },
    ownerName(){
      let user = this.users.find(u => u.id == this.session.ownerId)
      return user ? `${user.firstName} ${user.lastName}` : 'Session has no owner'
    },
    users(){
      return this.userList ?? this.internalUserList
    },
    availableTechnicalUsers(){  
      const sessionUserIds = this.sessionUsers.map(u => u.id)
      return this.users.filter(user => !sessionUserIds.includes(user.id))?.filter(u => u.lastName == 'technical' && (this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId))))      
    },
    pathPrefixes(){ 
      let tmp = JSON.parse(this.session.structure ?? this.session.experience.structure)
      return { 
        webContentRootPath: tmp.webContentRootPath,
        deviceRootPath: '/VRData/' + tmp.menuFile.split('/')[0] + '/'
      }
    },
    devices(){      
      let report = this.sessionUsers.map(su => {
        let deviceJobStatus = this.mdmjobstatus.find(s => s.device.id == su.device?.id)
        let deviceFileList = su.device?.fileList?.filter(f => f.key.startsWith(this.pathPrefixes.deviceRootPath))
        let tmp = {
          userName: su.firstName,
          deviceName: su.device?.name,
          serial: su.device?.id,
          mdmId: su.device?.mdmId,
          mdmAccount: su.device?.mdmAccount,
          mdmStatus: su.device?.mdmStatus,          
          jobStatus: deviceJobStatus?.jobs.find(j => j.jobID == this.fileTransferJobId),
          deviceJobs: deviceJobStatus?.jobs,
          correctFiles: [],
          brokenFiles: []         
        }
        tmp.vgtDisabled = !su.device?.mdmId || su.device?.deviceName
        tmp.vgtSelected = !tmp.vgtDisabled && su.device?.mdmId && !tmp.jobStatus
        // compute file integrity
        this.session.fileList?.forEach(sessionFile =>{
          let fileKey = sessionFile.key.substring(this.pathPrefixes.webContentRootPath.length)          
          let deviceFile = deviceFileList?.find(f => f.key == `${this.pathPrefixes.deviceRootPath}${fileKey}`)
          if(deviceFile && deviceFile.size == sessionFile.size)
            tmp.correctFiles.push(deviceFile)
          else{
            tmp.brokenFiles.push({
              key: fileKey,
              size: deviceFile?.size,
              serverLastModified: sessionFile.lastModified,
              lastModified: deviceFile?.lastModified,
              reason: !deviceFile 
                ? 'missing'
                : `${deviceFile.size} instead of ${sessionFile.size} bytes`
            })
          }  
        })
        return tmp
      })
      return report
    }    
  },
  methods: {
    getBatteryClass(mdmStatus){
      if(mdmStatus.charging == 'dfgCharging')
        return 'charging'
      else if (Number(mdmStatus.battery) >= 90)
        return 'high'
      else if (Number(mdmStatus.battery) >= 50)
        return 'medium'
      else
        return 'low'
    },
    deviceSearchFn(row/*, col, cellValue, searchTerm*/){
      return row.userName.toLowerCase().includes(this.deviceSearchTerm.toLowerCase())
    },
    fileDetails(device){
      if(!device.deviceName) // cannot show anything for unlinked devices
        return
      this.tempDevice = device
      this.deviceErrorsModal = true
    },
    async markReady(){
      await this.setWorkflow(4)
      this.$emit('close')
    },
    async setWorkflow(status){
      try{
        this.loading = true
        this.$nprogress.start()    
        await axios.post(`sessions/${this.session.id}/setworkflow?status=${status}`)
        this.$emit('updated', status)
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async applyFileTransferJob(){
      try{
        this.error = undefined
        this.loading = true
        this.mdmloading = true
        await axios({ url: `sessions/${this.session.id}/applymdmjob?job=filetransfer`, method: "POST", data: this.selectedDevices.map(d => d.mdmId) }) 
        this.$refs.snackbar.show('File transfers started on devices. Please do not start another transfer while some devices are in Pending state.', 5000)   
        setTimeout(() => this.refreshMdm(), 5000)     
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
      }
    },  
    async applyEmptyFolderJob(){
      try{
        this.error = undefined
        this.loading = true
        this.mdmloading = true
        await axios({ url: `sessions/${this.session.id}/applymdmjob?job=emptyfolder`, method: "POST", data: this.selectedDevices.map(d => d.mdmId) }) 
        this.$refs.snackbar.show('Content deletion started on devices. Please wait a few minutes before pushing any new content!', 5000)   
        setTimeout(() => this.refreshMdm(), 5000)    
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
      }
    }, 
    humanFileSize(size){
      return humanFileSize(size)
    },
    rowStyleClassFn(row) {
      let classes = row.mdmStatus?.connectionStatus == 'Online' ? 'online' : 'offline'
      if(!row.deviceName)
        classes += ' unlinked'
      if(!row.mdmId)
        classes += ' nomdm'
      return classes
    },
    async getMdmJobStatus(){
      try{
        this.error = undefined
        //this.loading = true
        let resp = await axios.get(`sessions/${this.session.id}/mdmjobstatus`)         
        this.mdmjobstatus = resp.data
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        //this.loading = false
      }
    },
    async getMdmDeviceStatus(){
      try{
        this.error = undefined
        //this.loading = true
        let resp = await axios.get(`sessions/${this.session.id}/mdmdevicestatus`)         
        for (let i = 0; i < resp.data.length; i++) {
          let status = resp.data[i]
          let user = this.sessionUsers.find(u => u.device && u.device.mdmId == status.mdmId)          
          if(user)
            this.$set(user.device, 'mdmStatus', status)
        }            
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        //this.loading = false
      }
    },
    selectionChanged(){
      this.addDisabled = this.$refs.usersdatatable?.selectedRows?.length == 0
      this.removeDisabled = this.$refs.sessionusersdatatable?.selectedRows?.length == 0
    },
    devicesSelectionChanged(){
      this.vgtReactiveTrigger++
    },
    onSelectAllDevices(p){
      // workaround for https://github.com/xaksis/vue-good-table/issues/848
      // params.selected - whether the select-all checkbox is checked or unchecked
      // params.selectedRows - all rows that are selected (this page)
      p.selectedRows.forEach(device => {
        if(!device.mdmId || !device.deviceName)
          device.vgtSelected = false
      })
    },
    async addUsers(){                 
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.usersdatatable.selectedRows.map(u => u.id)        
        await axios({ url: `sessions/${this.session.id}/users`, data: ids, method: 'POST' })
        await this.fetchUsers()
      }
      catch(err){
        console.log("Error when adding users to session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }      
    },
    async removeUsers(){ 
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.sessionusersdatatable.selectedRows.map(u => u.id)        
        await axios({ url: `sessions/${this.session.id}/users`, data: ids, method: 'DELETE' })
        await this.fetchUsers()        
      }
      catch(err){
        console.log("Error when removing users from session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      } 
    },
    async fetchUsers(){  
      if(!this.userList && (!this.internalUserList?.length))
        this.internalUserList = (await axios({ url: "users/technical", method: "GET" })).data
      let resp = await axios({ url: `sessions/${this.session.id}/users` })       
      this.sessionUsers = resp.data
    },
    async refreshMdm(mdmOnly){
      try{
        this.mdmloading = true
        // file list
        if(!mdmOnly){
          let resp = await axios({ url: `sessions/${this.session.id}/users` })
          for (let index = 0; index < resp.data.length; index++) {
            const element = this.sessionUsers.find(su => su.id == resp.data[index].id) 
            if(element.device)
              this.$set(element.device, 'fileList', resp.data[index].device.fileList)
          }
        }
        // mdm info
        await this.getMdmDeviceStatus()
        await this.getMdmJobStatus()     
      } 
      finally{
        this.mdmloading = false
      }
    }
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true
      this.$store.state.showLoader = true
      let x = await axios.get(`sessions/${this.$route.params?.id ?? this.sessionId}`)  // fetch full session details  
      this.session = x.data  
      await this.fetchUsers()      
      await this.refreshMdm(true)
    }
    finally{
      this.step = this.sessionUsers.length > 0 ? 2 : 1
      this.loading = false
      this.$nprogress.done()
      this.$store.state.showLoader = false
    }
  },
  created() {
    if(!this.tickerInterval)
      this.tickerInterval = setInterval(() => this.refreshMdm(), 60000)      
  },
  beforeDestroy: function(){
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)
    this.$store.state.showLoader = false
  }
}
</script>

<style lang="scss">
.manage-session{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  padding: 65px 88px;
  background-color: #f4f4f4;
  z-index: 20;
  
  .steps{
    height: 26px;
    margin: 20px 0 15px 0;
    .step{
      font-size: 18px;
      color: #a2a2a2;
      float: left;
      margin-right: 24px;
      cursor: pointer;
      opacity: 0.8;
      &:hover{
        opacity:1;
      }

      span{
        display: inline-block;
        border-radius: 50px;
        border: 2px solid #a2a2a2;
        text-align: center;
        width: 26px;
        height: 26px;
        font-size: 16px;
        line-height: 22px;
      }

      &.active{
        color: #4cad82;
        span{
          border: 2px solid #4cad82;
        }
      }
    }
  }

  .vgt-inner-wrap{
    box-shadow: none;
  }
  table.vgt-table thead th:not(:first-child){
    padding-left: 8px;
  }
  table.vgt-table thead th{
    background-color: #eef2f5;
  }
  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    th, td{
      padding: 0 2px;      
      vertical-align: middle;    
    }
  }

  .tabs{
    padding: 16px 24px 24px;
    border-radius: 8px;
    border: solid 1px #d5d5d6;
    background-color: #fff;
    position: relative;

    .usertabs{
      display: flex;
      justify-content: space-between; 

      .middletab{
        flex-basis: 100px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        button[disabled]{
          opacity: 0.3;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
        }
      }

      .usertab{    
        flex-grow: 1;
        // margin: 0 auto;
        // max-height: 500px;
        // overflow: auto;      
        b {
          white-space: nowrap;
          font-size: 20px;
          padding-right: 20px;
          padding-top:2px;
        }

        &.deploysteps{
          img{
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    .buttonsrow{
      margin-top:20px;
      display: flex;
      justify-content: flex-end;
      gap:16px;
      position: absolute;
      bottom: 24px;
      right: 24px;
    }
  }

  .devicesdatatable{

    .vgt-responsive{
      overflow-x: hidden;
    }
      
    table.vgt-table{
      font-size: 14px;      
      
      tr{
        white-space: nowrap;
      }

      input{
        height: unset;
      }
    }

    .storage{
      &.warning{
        color:red;
      }
    }

    .files{
      cursor: pointer;
      border-radius: 3px;
      &:hover{
        background-color: #df9090;
        color: white;
      }

      &::before{
        display: inline-block;
        content: ' ';
        width: 12px;
        height:12px;
        background: url(../assets/faild.svg);
        background-size: 12px 12px;
      }

      &.ready{
        &:hover{
          background-color: #86c596;
        }
        &::before{
          background: url(../assets/approved.svg);
          background-size: 12px 12px;
        }
      }
    }

    .connection{
      &::before{
        content:'';
        width:12px;
        height: 12px;
        display: inline-block;
        border-radius: 20px;
        background-color: #4cad82;
        margin-right:9px;
      }
    }
    
    .offline{
      .connection::before{
        background-color: lightgray;
        font-weight: bold;
      }
      .power{
        opacity: 0.3;
      }
    }

    .power{
      &::before{
        content:'';
        width:20px;
        height: 20px;
        display: inline-block;
        background: url(../assets/low-battery.svg) no-repeat;
        vertical-align: middle;
      }

      &.medium::before{
        background: url(../assets/medium-battery.svg) no-repeat;
      }

      &.high::before{
        background: url(../assets/high-battery.svg) no-repeat;
      }

      &.charging::before{
        background: url(../assets/charge-battery.svg) no-repeat;
      }
    }

    .unlinked{
      opacity: 0.3;
      .files{
        cursor: unset;
        &:hover{
          background-color: unset;
          color: unset;
        }
      }
    }

    .unlinked, .nomdm{
      input{
        display: none;
      }
      .vgt-checkbox-col{
        pointer-events:none;
      }
    }    

    &.manual{
      thead th:nth-child(6), tbody td:nth-child(6){
        span{
          opacity: 0.3;
        }
      }
    }
  }
}

</style>
