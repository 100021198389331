<template>
  <main class="sessions2 padded" style="padding-bottom:0;">
    <h2>Sessions</h2>

    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="buttons">
      <input v-model.trim="searchTerm" type="text" style="width: 300px;" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <button class="white" @click="addSession">Create session</button>
    </div>      
    
    <!-- <div class="scrollbox"> -->
      <VueGoodTable
      ref="datatable"
      :columns="columns"
      :rows="sessions"      
      styleClass="vgt-table"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      max-height="calc(100lvh - var(--size-header) - 150px - 70px)"
      :fixed-header="false"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">
        <template v-slot:selected-row-actions>  
          <button class="insead autowidth right" :disabled="loading || !selectedSessions.length || (!$store.getters.isAdmin && !$store.getters.isOrgAdmin && selectedSessions.filter(s => s.ownerId != $store.getters.userId).length > 0)" :class="{disabled: loading || !selectedSessions.length}" @click="bulkDelete">Bulk delete</button>              
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">               
            <button class="icon" title="Open" @click="$router.push('dashboard2/' + props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/preview-icon.svg" />
            </button>
            <button class="icon" title="Manage workflow" @click="editRowWorkflow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/off.svg" />
            </button>
            <button class="icon" title="Manage devices" @click="manageRow(props.row.id)" :disabled="![3,4].includes(props.row.workflow) || (!$store.getters.isAdmin && !$store.getters.isOrgAdmin)">
              <img src="../assets/manage.svg" />
            </button>      
            <button class="icon" title="Delete" @click="deleteRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/delete.svg" />
            </button>
            <button class="icon" title="Edit" @click="editRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/edit.svg" />
            </button>
            <button class="icon" title="Duplicate" @click="copyRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/duplicate.svg" />
            </button>
          </span>
          <span v-else-if="props.column.field == 'status'">
            {{formatSessionStatus(props.row.status)}}
          </span>
          <span v-else-if="props.column.field == 'workflow'">
            {{formatWorkflowStatus(props.row.workflow)}}
          </span>
          <span v-else-if="props.column.field == 'program'">
            {{formatProgramType(props.row.program)}}
          </span>
          <span v-else-if="props.column.field == 'startDate'">
            {{ props.row.startDate ? formatDate(new Date(props.row.startDate), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.startDate), $store.getters.timeZone).getFullYear()}` : '-' }}
          </span>
          <span v-else-if="props.column.field == 'endDate'">
            {{ props.row.endDate ? formatDate(new Date(props.row.endDate), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.endDate), $store.getters.timeZone).getFullYear()}` : '-' }}
          </span>
          <span v-else-if="props.column.field == 'created'">
            {{ `${formatDate(new Date(props.row.created), $store.getters.timeZone)} ${toTimeZone(new Date(props.row.created), $store.getters.timeZone).getFullYear()}` }}
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>
    <!-- </div> -->

    
    <ScheduleSession ref="editor" v-show="showModal"
      :headerTitle="($route.name == 'Session2Edit' ? 'Edit' : 'Create') + ' session'"
      :userList="users" 
      :organizationList="organizations"  
      @created="sessionCreated" 
      @updated="sessionUpdated"           
      @close="showModal = false; $router.push({name: 'Sessions2'}); " />
      
    <Modal2 v-if="sessionScheduledModal" @close="sessionScheduledModal = false;">      
      <div slot="body" style="width: 336px; text-align:center;">
        <img style="margin-top:74px;" src="../assets/mail.svg">
        <div>
          <div style="font-size:20px; padding:50px 0 25px 0;">Great!</div>          
          
          <div v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
            The session was created successfully. 
            <br>
            You will be reminded a week before the session.
          </div>
          <div v-else>
            Your session has been created successfully.
            <br>
            An admin will be notified to prepare the session.
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="white right" @click="sessionScheduledModal = false;">Continue</button>
      </div>
    </Modal2>

    <ManageSession v-if="sessionManageModal" :sessionId="tempSession.id" @updated="sessionWorkflowUpdated" @close="sessionManageModal = false; $router.push({name: 'Sessions2'}); " />

    <SessionWorkflow v-if="sessionWorkflowModal" :session="tempSession" @close="sessionWorkflowModal = false;" @updated="sessionWorkflowUpdated" />

    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';

import { formatSessionStatus, formatWorkflowStatus, formatProgramType, formatDate, toTimeZone } from '@/utils.js'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
//import {Roles} from '@/roles.js'
import ScheduleSession from '../components/ScheduleSession.vue'
import ManageSession from '../components/ManageSession.vue'
import Snackbar from '@/components/Snackbar.vue'
import Modal2 from '../components/Modal2.vue'
import SessionWorkflow from '../components/SessionWorkflow.vue'

export default {
  name: 'Sessions2',
  data: function(){
    return {
      // sessionUserColumns: [
      //   {
      //     label: 'Email',
      //     field: 'email',
      //   },
      //   {
      //     label: 'Organization',
      //     field: 'organization.name',
      //   },
      //   {
      //     label: 'First Name',
      //     field: 'firstName',
      //   },
      //   {
      //     label: 'Last Name',
      //     field: 'lastName',
      //   },  
      //   // {
      //   //   label: 'ID',
      //   //   field: 'id',
      //   // },
      // ],
      columns: [
        {
          label: 'Organization',
          field: 'organizationName',
          filterOptions: {
            enabled: this.$store.getters.isAdmin, 
            placeholder: 'No filter'
          },
          sortable: false,
        },
        {
          label: 'Id',
          field: 'id',
          //sortable: false,
          type: 'number',
        },
        {
          label: 'Workflow',
          field: 'workflow',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: ['Requested', 'Rejected', 'Updated', 'Approved', 'Ready'],
            filterFn: this.workflowFilterFn
          },
          sortable: false       
        },    
        {
          label: 'Practice',
          field: 'practice',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: [true, false]
          },
          sortable: false                    
        },      
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Experience',
          field: 'experienceName',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: this.experienceNames
          },
          sortable: false
        },
        {
          label: 'Start Date',
          field: 'startDate',          
        },
        {
          label: 'End Date',
          field: 'endDate',
        },
        {
          label: 'Created',
          field: 'created',
        }, 
        // {
        //   label: 'Structure',
        //   field: 'structure',
        // },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            //styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'No filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            filterDropdownItems: ['NotStarted','Guided','Paused','SelfPaced','Finished'], // dropdown (with selected values) instead of text input
            filterFn: this.statusFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
          sortable: false,
        },
        {
          label: 'Learners',
          field: 'userCount',
          type: 'number',
        },
        {
          label: 'Program',
          field: 'program',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: ['Degree', 'Executive'],
            filterFn: this.programFilterFn
          }
        },
        {
          label: 'Location',
          field: 'location',
        },
        // {
        //   label: 'OwnerId',
        //   field: 'ownerId',
        //   sortable: false,
        // },
        // {
        //   label: 'Experience',
        //   field: 'experience.name',
        //   filterOptions: {
        //     //styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Filter by Experience', // placeholder for filter input
        //     // filterValue: 'Jane', // initial populated value for this filter
        //     // filterDropdownItems: experiences.map(e => e.name), // dropdown (with selected values) instead of text input
        //     // filterFn: this.columnFilterFn, //custom filter function that
        //     // trigger: 'enter', //only trigger on enter not on keyup 
        //   },
        // },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ].filter(c => this.$store.getters.isAdmin ? true : c.label != 'Organization'),      
      sessions: [],
      experiences: [],
      organizations: [],
      loading: false,
      error: undefined,
      searchTerm: undefined,
      //userSearchTerm: undefined,
      //sessionUserSearchTerm: undefined,
      showModal: false,  
      sessionScheduledModal: false,
      sessionManageModal: false,
      sessionWorkflowModal: false,
      //showUsersModal: false,  
      //removeUsersModal: false,
      //showAdminsModal: false,  
      //removeAdminsModal: false,
      //selectedTreeItem: undefined,
      //selectedContentBlock: undefined,
      //publishContent: false,
      // tempSession: {
      //   id: undefined,
      //   name: undefined,
      //   startDate: undefined,
      //   endDate: undefined,
      //   structure: undefined,
      //   ownerId: undefined,
      //   custom: undefined,
      //   contentBlocks: [],
      //   organizationId: null
      // }, 
      tempSession: undefined,
      users: []
    }
  },
  computed: {     
    selectedSessions(){
      return this.$refs.datatable.selectedRows;
    }
  },
  methods: {  
    setExperiencesFilters(){
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'experienceName'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.$store.getters.orderedExperiences.map(e => e.name))
    }, 
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    formatSessionStatus(status){
      return formatSessionStatus(status)
    },    
    formatWorkflowStatus(status){
      return formatWorkflowStatus(status)
    },
    formatProgramType(type){
      return formatProgramType(type)
    },
    programFilterFn: function(data, filterString) {     
      return this.formatProgramType(data) == filterString
    },
    statusFilterFn: function(data, filterString) {     
      return this.formatSessionStatus(data) == filterString
    },
    workflowFilterFn: function(data, filterString) {     
      return this.formatWorkflowStatus(data) == filterString
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data

      // set filters
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'organizationName'
      })        
      if(this.$store.getters.isAdmin)   
        this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.organizations.map(o => o.name));
    },    
    async bulkDelete(){
      if(confirm(`Delete the ${this.selectedSessions.length} selected sessions?`)){
        let forDeletion = this.selectedSessions.map(s => s.id)
        await axios({ url: `sessions`, data: forDeletion, method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => !forDeletion.includes(s.id))
      }
    },
    async deleteRow(id){
      if(confirm(`Delete session with id ${id}?`)){
        await axios({ url: `sessions`, data: [id], method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => s.id != id)
      }
    },    
    addSession(){
      this.$refs.editor.create()
      this.showModal = true
    },
    async getSessions(){      
        let resp = await axios({ url: "sessions" }) 
        this.sessions = resp.data;      
    },  
    async fetchUsers(){      
        let resp = await axios({ url: "users", method: "GET" }); 
        this.users = resp.data;      
    },
    async editRow(id){     
      if(!this.$route.params.id)
        this.$router.push({name: 'Session2Edit', params: {id: id}}) 
      let tmp = JSON.parse(JSON.stringify(this.sessions.find(s => s.id == id))) 
      this.$refs.editor.edit(tmp)
      this.showModal = true
    },
    async copyRow(id){           
      let tmp = JSON.parse(JSON.stringify(this.sessions.find(s => s.id == id)))          
      this.$refs.editor.create(tmp)
      this.showModal = true
    },
    async manageRow(id){     
      if(!this.$route.params.id || !this.$route.name == 'Session2Manage')
        this.$router.push({name: 'Session2Manage', params: {id: id}})      
      this.tempSession = this.sessions.find(s => s.id == id)                           
      this.sessionManageModal = true
    },
    async editRowWorkflow(id){  
      this.tempSession = this.sessions.find(s => s.id == id) 
      this.sessionWorkflowModal = true
    },
    sessionUpdated(session){
      let indexOfItem = this.sessions.findIndex(s => s.id == session.id)      
      this.$set(this.sessions, indexOfItem, session)
      this.showModal = false
      this.$router.push({name: 'Sessions2'}) 
      this.$refs.snackbar.show('Session updated successfully')
    },
    sessionWorkflowUpdated(status, reason){
      const readyRevoked = this.tempSession.workflow == 4 && status != 4
      this.tempSession.workflow = status
      this.tempSession.workflowMessage = reason
      this.sessionWorkflowModal = false
      if(readyRevoked)
        this.$refs.snackbar.show('Session\'s Ready state changed back to Approved')
      else if(status == 4)
        this.$refs.snackbar.show('Session marked as Ready')
      else
        this.$refs.snackbar.show('Request ' + (status == 3 ? 'approved' : 'rejected'))
    },
    sessionCreated(s){
      if(!s.organization && s.organizationId){
        s.organization = this.organizations.find(o => o.id == s.organizationId)
      }
      this.sessions.unshift(s)
      this.showModal = false
      this.sessionScheduledModal = true
    },
  },  
  components: {
    VueGoodTable, 
    Modal2,
    Snackbar,
    ScheduleSession,
    ManageSession,
    SessionWorkflow
  },
  watch:{
    async $route (to, from){
      //console.log('from: ' + from.name + ' to: ' + to.name)
        if(to != from && to.name == 'Session2Manage')
          await this.manageRow(this.$route.params.id)     
        if(from.name == 'Session2Edit' && to.name == 'Sessions2')
          this.$store.state.showLoader = false   
    },
    '$store.state.experiences': function() {
      this.setExperiencesFilters()
    }
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true      
      this.setExperiencesFilters()
      await this.getSessions()      
      await this.getOrganizations()
      await this.fetchUsers()
      
      if(this.$route.params.id){      
        if(this.$route.name == 'Session2Edit')
          await this.editRow(this.$route.params.id)
        else if(this.$route.name == 'Session2Manage')
          await this.manageRow(this.$route.params.id)        
        //   await this.editUsers(this.$route.params.id)
        // else if(this.$route.name == 'SessionAdmins')
        //   await this.editAdmins(this.$route.params.id)
      }
    }
    finally{
      this.loading = false
      this.$nprogress.done()
      // loading of manage screen takes time, so cancel loading from that component instead
      if(this.$route.name != 'Session2Manage' && this.$route.name != 'Session2Edit')
        this.$store.state.showLoader = false
    }
  },
  created() {
  }
}
</script>

<style lang="scss">

main.sessions2{  
  display: block;
  position: relative;

  h2{
    margin: 0 0 10px 0;
    font-size: 20px;    
    font-weight: bold;
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
    }
  }

  .scrollbox{
    height: calc(100lvh - var(--size-header) - 150px - 70px);
    overflow-y: auto;    
    padding-right: 10px;
    margin-right: -13px;

    .vgt-wrap{
      border-radius: 8px;
      border: solid 1px #d7d7d8;
      overflow: hidden;
    }
  }

  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    td{
      padding: 5px 8px;      
      vertical-align: middle;    
    }
  }

  .rowActions{
    display: flex;
    align-items: center;

    button{
      width:16px;
      height: 16px;
      min-width: unset;
      margin: 0 3px;

      img{
        width: 20px;
        height: 20px;        
      }

      &[disabled]{
        background-color: transparent;
        opacity: 0.2;
      }
    }
  }

  .formerror{
    position: absolute;
    top: 0;
    left: 88px;
    right: 88px;
  }

}
</style>
